/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export enum BaseRoutes {
  STUDENT = "/elev",
  TUTOR = "/pl",
}

export const RoutesConfig = {
  LOGIN: {
    name: "Logg inn",
    path: "/logg-inn",
    component: () => import("@/views/Login.vue"),
  },
  CONTACT_ME: {
    name: "Kontakt meg",
    path: "/kontakt-meg",
    component: () => import("@/views/onboarding/ContactMe.vue"),
  },
  CONTACT_ME_TARGET_GROUP: {
    name: "Kontakt meg",
    path: "/kontakt-meg/:targetGroup",
    component: () => import("@/views/onboarding/ContactMe.vue"),
  },
  ORDER_SELLER: {
    name: "Bestilling",
    path: "/bestilling",
    component: () => import("@/views/onboarding/AutomaticOnboarding.vue"),
  },
  ORDER_SELLER_TARGET_GROUP: {
    name: "Bestilling",
    path: "/bestilling/:targetGroup",
    component: () => import("@/views/onboarding/AutomaticOnboarding.vue"),
  },
  ORDER_SELLER_TARGET_GROUP_CUSTOMER_TYPE: {
    name: "Bestilling",
    path: "/bestilling/:targetGroup/:customerType",
    component: () => import("@/views/onboarding/AutomaticOnboarding.vue"),
  },
  SELLER_AVAILABILITY: {
    name: "Bli matchet",
    path: "/pl/bli-matchet",
    component: () => import("@/views/SellerAvailability.vue"),
  },
  SELLER_DASHBOARD: {
    name: "Oversikt",
    path: "/pl/",
    component: () => import("@/views/seller/SellerDashboard.vue"),
  },
  SELLER_LESSONS: {
    name: "Timer",
    path: "/pl/timer",
    component: () => import("@/views/seller/LessonsSeller.vue"),
  },
  SELLER_MY_STUDENTS: {
    name: "Mine elever",
    path: "/pl/mine-elever",
    component: () => import("@/components/seller/MyStudents.vue"),
  },
  SELLER_NEWS: {
    name: "Siste nytt",
    path: "/pl/siste-nytt",
    component: () => import("@/views/News.vue"),
  },
  SELLER_PAYOUTS: {
    name: "Utbetalinger",
    path: "/pl/utbetalinger",
    component: () => import("@/views/Payouts.vue"),
  },
  SELLER_PROFILE: {
    name: "Profil",
    path: "/pl/profil/:userId?",
    getPath(userId: string): string {
      return `/pl/profil/${userId}`;
    },
    component: () => import("@/views/Profile.vue"),
  },
  SELLER_REPORTS: {
    name: "Rapporter",
    path: "/pl/rapporter",
    component: () => import("@/views/Reports.vue"),
  },
  SELLER_RESOURCES: {
    name: "Ressurser",
    path: "/pl/ressurser",
    component: () => import("@/views/seller/Resources.vue"),
  },
  SELLER_SETTINGS: {
    name: "Innstillinger",
    path: "/pl/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
  CUSTOMER_DASHBOARD: {
    name: "Oversikt",
    path: "/elev/",
    component: () => import("@/views/customer/CustomerDashboard.vue"),
  },
  CUSTOMER_LESSONS: {
    name: "Timer",
    path: "/elev/timer",
    component: () => import("@/views/customer/LessonsCustomer.vue"),
  },
  CUSTOMER_SETTINGS: {
    name: "Innstillinger",
    path: "/elev/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
  CUSTOMER_MEMBERSHIP: {
    name: "Medlemskap",
    path: "/elev/medlemskap",
    component: () => import("@/views/customer/Membership.vue"),
  },
  CUSTOMER_NEWS: {
    name: "Siste nytt",
    path: "/elev/siste-nytt",
    component: () => import("@/views/News.vue"),
  },
  CUSTOMER_REPORTS: {
    name: "Rapporter",
    path: "/elev/rapporter",
    component: () => import("@/views/Reports.vue"),
  },
  REGISTER: {
    name: "Registrer",
    path: "/registrer",
    component: () => import("@/views/onboarding/Register.vue"),
  },
  VIDEOS: {
    name: "Videoer",
    path: "/videoer",
    component: () => import("@/views/VideoLibrary.vue"),
  },
};
