import { Notification } from "@/providers/notifier";
import * as Sentry from "@sentry/vue";

export default function handleError(error: Error): void {
  // eslint-disable-next-line no-console
  console.error(error);
  Sentry.captureException(error);
}

export function getAuthErrorNotification(authErrorCode: string): Notification {
  if (authErrorCode === "auth/wrong-password") {
    return {
      title: "Feil passord",
      message: "",
      type: "error"
    };
  }
  else if (authErrorCode === "auth/user-not-found") {
    return {
      title: "Fant ikke brukeren",
      message: "",
      type: "error"
    };
  }
  else if (authErrorCode === "auth/invalid-email") {
    return {
      title: "Ugyldig e-postadresse",
      message: "",
      type: "error"
    };
  }
  else if (authErrorCode === "auth/network-request-failed") {
    return {
      title: "Fant ikke nettet",
      message: "Det ser ut til at internett-tilkoblingen din er borte. Det kan hjelpe å starte nettleseren på nytt.",
      type: "error"
    };
  }
  else if (authErrorCode === "auth/account-exists-with-different-credential") {
    return {
      title: "Feil innloggingsmetode",
      message: "Du har en annen innloggingsmetode tilknyttet kontoen din 🙂",
      type: "error"
    };
  }
  return {
    title: "Ukjent feil",
    message: "Kontakt support hvis problemet vedvarer.",
    type: "error"
  };
}
